
let CLIENT_DATA = {};
const OPTIONS = {
    CLIENT: '',
    DOWNONLY: ''
};

const LOCATIONS = {
    DEV: [
        'localhost',
        'ui.support.dev.ab-net.us'
    ],
    PROD: [
        'ui.support.prod.ab-net.us'
    ]
};

const CLIENTS = {
    DEV: [
        'dev',
        'qa'
    ],
    PROD: [
        'ega',
        'open',
        'parkland',
        'rutters',
        'sheetz',
        'ta'
    ]
};

function setClientData(client, data) {
    CLIENT_DATA[client] = data;
}

async function fetchClientData(client) {
    const data = await fetch(`https://${client}.cms.ab-net.us/api/dumpdata`);

    if (data.ok) {
        setClientData(client, await data.json());
    }
    else {
        console.log('Render.fetchClientData error: ', client);
    }
}

async function fetchAllCientsData() {
    const url = new URL(window.location);
    const promises = [];
    let clients = CLIENTS.PROD;
    
    if (LOCATIONS.DEV.indexOf(url.hostname) !== -1) {
        clients = CLIENTS.DEV;
    }

    for (var i = 0; i < clients.length; i++) {
        let promise = fetchClientData(clients[i]);

        promises.push(promise);
    }

    return Promise.all(promises);
}

async function setOptions(options = {}) {
    OPTIONS.CLIENT = (options && options.client) ? options.client : '';
    OPTIONS.DOWNONLY = (options && options.downonly) ? options.downonly : '';

    if (OPTIONS.CLIENT) {
        await fetchClientData(OPTIONS.CLIENT);
    }
    else if (!OPTIONS.CLIENT) {
        await fetchAllCientsData();
    }
}

function find(client, macAddress, property) {
    let value = '';
    let clientData = '';

    clientData = CLIENT_DATA[client];

    if (!clientData) {
        return value;
    }

    for (var i = 0; i < clientData.length; i++) {
        if (clientData[i]['device_macaddress'] === macAddress) {
            if (property) {
                value = clientData[i][property];
            }
            else {
                value = clientData[i];
            }
        }
    }

    return value;
}

function displayName(d, type, row) {
    return find(row.client, row.macAddress, 'display_name');
}

function deviceName(d, type, row) {
    return find(row.client, row.macAddress, 'device_name');
}

function deviceVersion(d, type, row) {
    return find(row.client, row.macAddress, 'device_version');
}

function appVersion(d, type, row) {
    return find(row.client, row.macAddress, 'app_version');
}

function siteName(d, type, row) {
    return find(row.client, row.macAddress, 'site_name');
}

function appName(d, type, row) {
    return find(row.client, row.macAddress, 'app_name');
}

function operatingSystem(d, type, row) {
    const operatingSystem = row.operatingSystem || '';
    const osVersion = row.osVersion || '';

    return `${operatingSystem} ${osVersion}`;
}

function dateTime(epoch, type) {
    if (type === 'sort') {
        return parseInt(epoch);
    }

    const TIMEOUT = 15 * 60 * 1000;  // 15 minutes
    const diff = Date.now() - parseInt(epoch);
    let date = new Date(epoch).toLocaleString('en');

    // if longer than timeout since last ping, add warning
    if (diff > TIMEOUT) {
        date = warning(date);
    }

    if (date === 'Invalid Date') {
        return '';
    }

    return date;
}

function screenshot(base64, type, row) {
    if (!base64 || base64 === 'data:image/jpeg;base64,' || base64 === 'data:image/jpeg;base64,undefined') {
        return '<div class="screenshot" />';
    }

    if (row.ledstatus !== 'n/a' && row.ledstatus !== 'Led not found') {
        return `<div class="led"><img class="screenshot" src="${base64}?${Date.now()}" /></div>`;
    }

    return `<img class="screenshot" src="${base64}?${Date.now()}" />`;
}

function warning(text) {
    return `<span class="warning">${text}</span>`;
}

function links(d, type, row) {
    const allLink = `?client=${row.client}&downonly=`;
    const downLink = `?client=${row.client}&downonly=true`;

    return `<a href="${allLink}" class="viewall">All</a> | <a href="${downLink}" class="viewdown">Down</a>`;
}

function getDevice(row) {
    return find(row.client, row.macAddress);
}

const renderInterface = {
    setOptions,
    displayName,
    deviceName,
    deviceVersion,
    appVersion,
    siteName,
    appName,
    operatingSystem,
    dateTime,
    screenshot,
    warning,
    links,
    getDevice
};

export default renderInterface;
