/* src/App.js */
import React, { useEffect } from 'react';
import { Amplify } from 'aws-amplify';
import { withAuthenticator, Button, Heading } from '@aws-amplify/ui-react';
import $ from 'jquery';
import _ from 'lodash';
import '@aws-amplify/ui-react/styles.css';
import Render from './components/Render';
import Table from './components/Table';
import ClientDeviceData from './components/ClientDeviceData';
import awsExports from './aws-exports';

Amplify.configure(awsExports);

const App = ({ signOut, user }) => {
    const OFFLINE_TIMEOUT = 15 * 60 * 1000; // 15 minutes
    let CLIENTS_TABLE;
    let DEVICES_TABLE;
    let WALL_TABLE;
    let CLIENT = '';
    let APP_RENDERED = false;
    let OPEN_ROWS = [];

    function navigation() {
        const title = $('#title');
        const backLink = $('#back');
        const downOnly = $('#downonly');
    
        backLink.on('click', () => {
            window.location.href = '/';
        });

        downOnly.on('click', e => {
            if ($(e.target).text() === 'Show All Devices') {
                window.location.href = `/?client=${CLIENT}&downonly=`;
            }
            else {
                window.location.href = `/?client=${CLIENT}&downonly=true`;
            }
        });
    
        backLink.hide();
        downOnly.hide();
        title.text('Client list');
    }

    function renderTables() {

        CLIENTS_TABLE = new Table({
            container: 'clients',
            dataTable: {
                autoWidth: false,
                order: [0, 'asc'],
                columns: [
                    { defaultContent: '', data: 'client', title: 'Client' },
                    { defaultContent: '', data: 'devices', title: 'Total Devices' },
                    { defaultContent: '', data: 'devicesdown', title: 'Devices Down', render: Render.warning },
                    { defaultContent: '', title: 'View', render: Render.links }
                ],
                fixedHeader: {
                    header: false,
                    footer: true
                },
                footerCallback: function(tfoot) {
                    const api = this.api();
                    const devices = api.column(1).data().reduce((a, b) => {
                        return a + b;
                    }, 0);
                    const down = api.column(2).data().reduce((a, b) => {
                        return a + b;
                    }, 0);

                    $(tfoot).find('th').eq(0).html('TOTALS');
                    $(tfoot).find('th').eq(1).text(devices);
                    $(tfoot).find('th').eq(2).html(`<span class="warning">${down}</span>`);

                    const downLink = `?client=&downonly=true`;

                    $(tfoot).find('th').eq(3).html(`<a href="${downLink}" class="viewdown">All Down</a>`);
                }
            }
        });
    
        DEVICES_TABLE = new Table({
            container: 'devices',
            dataTable: {
                autoWidth: false,
                dom: '<"tableheader"fl><t><"tablefooter"ip>',
                order: [3, 'asc'],
                paging: true,
                pagingType: 'full',
                columns: [
                    { defaultContent: '', orderable: false, render: Render.details, className: 'xsmall' },
                    { defaultContent: '', data: 'client', title: 'Client', className: 'small'},
                    { defaultContent: '', data: 'macAddress', title: 'MAC Address', className: 'small' },
                    { defaultContent: '', title: 'Site Name', render: Render.siteName, className: 'medium' },
                    { defaultContent: '', title: 'Display Name', render: Render.displayName, className: 'large' },
                    { defaultContent: '', title: 'Device Name', render: Render.deviceName, className: 'large' },
                    { defaultContent: '', title: 'Device Version', render: Render.deviceVersion, className: 'small' },
                    { defaultContent: '', title: 'App Name', render: Render.appName, className: 'large' },
                    { defaultContent: '', title: 'App Version', render: Render.appVersion, className: 'small' },
                    { defaultContent: '', title: 'OS', render: Render.operatingSystem, className: 'small' },
                    { defaultContent: '', data: 'model', title: 'Model', className: 'medium' },
                    { defaultContent: '', data: 'unixepoch', title: 'Last Ping', render: Render.dateTime, className: 'large' },
                    { defaultContent: '', data: 'screenshot', title: 'Screenshot', render: Render.screenshot, className: 'medium' }
                ],
                columnDefs: [
                    { type: 'natural-nohtml', targets: '_all', },
                    { targets: 0, 'createdCell': (td, d, row) => {
                        $(td).attr('id', row.macAddress);

                        let highlight = false;
                        let dateTime = Render.dateTime(row.unixepoch);

                        if (dateTime.includes('warning') || row.oopsscreen === 'true') {
                            highlight = true;
                        }
        
                        if (row.wall && row.wall.length) {
                            $(td).addClass('details-control');
                            
                            _.forEach(row.wall, device => {
                                dateTime = Render.dateTime(device.unixepoch);
                                
                                if (dateTime.includes('warning') || device.oopsscreen === 'true') {
                                    highlight = true;
                                }
                            })
                        }
        
                        if (highlight) {
                            $(td).addClass('highlight');
                        }
                        else {
                            $(td).removeClass('highlight');
                        }
                    }}
                ]
            }
        });

        WALL_TABLE = new Table({
            container: 'wall',
            dataTable: {
                autoWidth: false,
                dom: '',
                order: [3, 'asc'],
                columns: [
                    { defaultContent: '', orderable: false, className: 'xsmall' },
                    { defaultContent: '', data: 'client', title: 'Client', className: 'small'},
                    { defaultContent: '', data: 'macAddress', title: 'MAC Address', className: 'small'},
                    { defaultContent: '', title: 'Site Name', render: Render.siteName, className: 'medium' },
                    { defaultContent: '', title: 'Display Name', render: Render.displayName, className: 'large' },
                    { defaultContent: '', title: 'Device Name', render: Render.deviceName, className: 'large' },
                    { defaultContent: '', title: 'Device Version', render: Render.deviceVersion, className: 'small' },
                    { defaultContent: '', title: 'App Name', render: Render.appName, className: 'large' },
                    { defaultContent: '', title: 'App Version', render: Render.appVersion, className: 'small' },
                    { defaultContent: '', title: 'OS', render: Render.operatingSystem, className: 'small' },
                    { defaultContent: '', data: 'model', title: 'Model', className: 'medium' },
                    { defaultContent: '', data: 'unixepoch', title: 'Last Ping', render: Render.dateTime, className: 'large' },
                    { defaultContent: '', data: 'screenshot', title: 'Screenshot', render: Render.screenshot, className: 'medium' }
                ],
                columnDefs: [
                    { type: 'natural-nohtml', targets: '_all', },
                    { targets: 0, 'createdCell': (td, d, row) => {
                        $(td).addClass('details-control invisible');
                        $(td).attr('id', row.macAddress);

                        let dateTime = Render.dateTime(row.unixepoch);
      
                        if (dateTime.includes('warning') || row.oopsscreen === 'true') {
                            $(td).addClass('highlight');
                        }
                        else {
                            $(td).removeClass('highlight');
                        }
                    }}
                ],
                language: {
                    infoFiltered: '',
                    emptyTable: 'Failed to load additional devices. Make sure the Video Wall is configured correctly on OPEN.'
                }
            }
        });

        $('#devices').on('click tap', 'td.details-control', function () {
            const td = $(this);
            const tr = $(this).closest('tr');
            const row = $(this).closest('table').DataTable().row(td);
            const id = td.attr('id');

            if (td.hasClass('shown')) {
                td.removeClass('shown');
                row.child().hide();

                OPEN_ROWS.filter((value, index, arr) => {
                    if (value === id) {
                        arr.splice(index, 1);
                        return true;
                    }

                    return false;
                });
            }
            else {
                const div = $('<div>').addClass('loading').text('Loading...');

                row.child(div).show();
                row.child().addClass('extra');
                td.addClass('shown');

                let reverse = false;

                if (tr.hasClass('odd')) {
                    reverse = true;
                }

                row.child().find('div').replaceWith(cloneWallTable(row.data().wall, reverse));

                if (OPEN_ROWS.indexOf(id) === -1) {
                    OPEN_ROWS.push(id);
                }
            }
        });

        $('#devices').on('mouseenter', 'td .screenshot', async function() {
            const src = $(this).prop('src');
            const url = new URL(src);
            const epoch = new Date();

            url.search = epoch.getTime();

            const rsp = await fetch(url);
            const lastModified = rsp.headers.get('Last-Modified');
            const date = new Date(lastModified);
            const local = date.toLocaleString();

            $(this).prop('title', local);
        })
    }

    function cloneWallTable(devices, reverse)  {
        WALL_TABLE.update(devices);

        const clone = $('#wall').clone(true);

        clone.attr('id', '');
        clone.addClass('wall');
        clone.css('display', 'block')
        clone.find('thead').hide();

        if (reverse) {
            clone.find('tr').each(function(index, row) {
                if ($(row).hasClass('odd')) {
                    $(row).addClass('even');
                    $(row).removeClass('odd');
                }
                else {
                    $(row).addClass('odd');
                    $(row).removeClass('even');
                }
            })
        }

        return clone;
    }

    async function viewDevices(client = '', downonly = '') {
        const clientsTable = CLIENTS_TABLE.dataTable();
        const rowData = clientsTable.row($(this).parent()).data();

        client = (rowData && rowData.client) ? rowData.client : client;

        if (client && downonly) {
            $('#downonly').text('Show All Devices');
        }

        $('#clients_wrapper').hide();
        $('#devices').show();
        $('#back').show();

        if (client) {
            $('#downonly').show();
            $('#title').text(`Devices on ${client}`);
            DEVICES_TABLE.dataTable().column(1).visible(false);
            WALL_TABLE.dataTable().column(1).visible(false);
        }
        else {
            $('#title').text('All Down Devices');
            $('#devices table').addClass('smaller');
        }

        await setClient(client, downonly);
    }

    function listener(items) {
        const url = new URL(window.location.href);
        const downonly = url.searchParams.get('downonly');
        
        if (CLIENT || (!CLIENT && downonly)) {
            items = combineWallDevices(items);
            DEVICES_TABLE.update(items);
            openRows();
        }
        else {
            items = getClientList(items);
            CLIENTS_TABLE.update(items);
        }
    }

    function openRows() {
        OPEN_ROWS.forEach(mac => {
            $(`#${mac}`).trigger('click');
        });
    }

    function combineWallDevices(items) {
        const displays = {};

        items.forEach(item => {
            _.merge(item, Render.getDevice(item));
        });

        items = _.sortBy(items, ['display_name', 'display_info.screen']);

        items.forEach(item => {
            let displayName = Render.displayName(null, null, item) || item.device_macaddress;

            if (displayName === '') {
                displayName = item.device_macaddress;
            }

            if (!displayName) {
                return true;
            }

            if (!displays[displayName]) {
                displays[displayName] = item;
                displays[displayName].wall = [];
            }
            else {
                if (!displays[displayName].wall) {
                    displays[displayName].wall = [];
                }

                if (displays[displayName].client === item.client) {
                    displays[displayName].wall.push(item);
                }
            }
        });

        items = [];

        for (var display in displays) {
            items.push(displays[display]);
        }

        return items;
    }

    function getClientList(items) {
        const clients = {};
    
        function addClient(item) {
            if (!clients[item.client]) {
                clients[item.client] = {
                    devices: 0,
                    devicesdown: 0
                };
            }
    
            // add device
            clients[item.client].devices += 1;
    
            const diff = Date.now() - parseInt(item.unixepoch);
    
            // check if device is down
            if (diff > OFFLINE_TIMEOUT || item.oopsscreen === 'true') {
                clients[item.client].devicesdown += 1;
            }
        }

        items.forEach(item => {
            if (item.hasOwnProperty('macAddress')) {
                const device = Render.getDevice(item);

                if (device) {
                    addClient(item);
                }
            }
        });

        const clientList = [];
    
        for (var client in clients) {
            clientList.push({
                client: client,
                devices: clients[client].devices,
                devicesdown: clients[client].devicesdown
            });
        }
    
        return clientList;
    }

    function init() {
        if (user.username && !APP_RENDERED) {
            // setup navigation
            navigation();

            // render data tables
            renderTables();
            // set listener
            ClientDeviceData.setListener(listener);

            const url = new URL(window.location.href);
            const client = url.searchParams.get('client') || '';
            const downonly = url.searchParams.get('downonly') || '';

            if (user.username === 'rutters-support') {
                viewDevices('rutters', downonly);

                // hide back link
                $('#back').hide();
            }
            else if (client || (!client && downonly)) {
                viewDevices(client, downonly);
            }
            else if (!client && !downonly) {
                setClient();
            }

            APP_RENDERED = true;
        }
    }

    function logout() {
        $('#container').hide();

        setClient();
        ClientDeviceData.stopTimer();

        signOut();

        user.username = '';
    }

    async function setClient(client = '', downonly = '') {
        CLIENT = client;

        await Render.setOptions({ client: client, downonly: downonly });
        ClientDeviceData.setOptions({ client: client, downonly: downonly });
    }
    
    useEffect(() => {
        init();
    });

  return (
    <div id='container'>
        <div id='header'>
            <Heading id='title' level={1}></Heading>
            <div id='loggedin'>
                <div>Hello {user.username}</div>
                <Button id='logout' onClick={logout}>Sign out</Button>
            </div>
        </div>
        <div id='navigation'>
            <Button id='back' href='#'>Back to Client list</Button>
            <Button id='downonly' href='#'>Show Down Devices</Button>
        </div>
        <table id='clients'>
            <thead></thead>
            <tbody></tbody>
            <tfoot>
                <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                </tr>
            </tfoot>
        </table>
        <div id='devices'></div>
        <div id='wall'></div>
    </div>
  )
}

export default withAuthenticator(App, {
    hideSignUp: true
});
