import $ from 'jquery';
import 'bootstrap';
import 'datatables.net-bs5';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';

export default class Table {
    constructor(options) {
        this.table = null;

        this.defaults = {
            container: '',
            dataTable: {
                data: [],
                autoWidth: false,
                info: false,
                paging: false,
                language: {
                    infoFiltered: '',
                    emptyTable: 'loading...'
                },
                columnDefs: [
                    { type: 'natural-nohtml', targets: '_all' }
                ]
            }
        };

        this.init = function (options) {
            if (options) {
                this.defaults = $.extend(true, {}, this.defaults, options);
            }

            // check for container id
            if (this.defaults.container === '') {
                return;
            }

            this.table = $(`table#${this.defaults.container}`);

            if (!this.table.length) {
                this.table = $('<table class="display">');
            }

            if (options.id) {
                this.table.prop('id', options.id);
            }

            // move dataTable to container
            $('#' + this.defaults.container).append(this.table);

            this.table.DataTable(this.defaults.dataTable);
        };

        this.dataTable = function () {
            return $(this.table).DataTable();
        };

        this.update = function (data) {
            if (data) {
                this.dataTable().clear();
                this.dataTable().rows.add(data).draw('full-hold');
            }
        };

        this.init(options);

        return this;
    }
}
