
const INTERVAL = 30 * 1000; // get data every 30 seconds
const OPTIONS = {
    CLIENT: '',
    DOWNONLY: ''
};
let URL = 'https://img.support.dev.ab-net.us/data';
let TIMER = null;
let LISTENER = '';

const server = window.location.toString().replace('http://', '').replace('https://', '');
const client = server.split('.', 3).join('.');

if (client === 'ui.support.prod') {
    URL = 'https://img.support.prod.ab-net.us/data';
}

function setOptions(options = {}) {
    OPTIONS.CLIENT = (options && options.client) ? options.client : '';
    OPTIONS.DOWNONLY = (options && options.downonly) ? options.downonly : '';

    stopTimer();
    startTimer();
}

async function getClientData() {
    const url = `${URL}?client=${OPTIONS.CLIENT}&downonly=${OPTIONS.DOWNONLY}`;

    const response = await fetch(url);
    const result = await response.json();

    if (LISTENER && typeof LISTENER === 'function') {
        LISTENER(result);
    }
}

function setListener(listener) {
    LISTENER = listener;
}

async function startTimer() {
    if (!TIMER) {
        TIMER = setInterval(async function() {
            await getClientData();
        }, INTERVAL);
        await getClientData();
    }
    
}

function stopTimer() {
    if (TIMER) {
        clearInterval(TIMER);
        TIMER = null;
    }
}

const clientDataInterface = {
    setOptions,
    setListener,
    stopTimer
};

export default clientDataInterface;